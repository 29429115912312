"use strict";

var uri = new URL('/wp-json/trsn/v1/calculator', window.location.origin);
// const category = 'clickover';
var data = null;

// Fetch data from the REST API
fetch(uri).then(function (response) {
  return response.json();
}).then(function (json) {
  data = json[0] || [];
  populateProductDropdown(data);
})["catch"](function (error) {
  return console.error('Error fetching data:', error);
});

// Function to populate the product dropdown
function populateProductDropdown(data) {
  var productDropdown = document.getElementById('product');
  data.forEach(function (entry) {
    // if (!category || category === entry['theme-product-category']) {
    var option = new Option(entry['theme-product-name'], entry['theme-product-name']);
    productDropdown.add(option);
    // }
  });
}

// Function to trigger price calculation
function triggerChange() {
  var selectedProduct = document.getElementById('product').value;
  var productEntry = data.find(function (entry) {
    return entry['theme-product-name'] === selectedProduct;
  });
  if (productEntry) {
    var type = productEntry['theme-product-type'];
    toggleHeightField(type);
    var meters = calculateAreaInMeters(type);
    var price = calculatePrice(productEntry, meters);
    document.getElementById('output_price').textContent = "\u20AC ".concat(price.toFixed(0), ",-");
  }
}
function toggleHeightField(type) {
  var heightField = document.getElementById('height').parentNode;
  if (type === 'meter') {
    heightField.style.display = 'none';
  } else {
    heightField.style.display = 'block';
  }
}

// Helper function to calculate area based on product type
function calculateAreaInMeters(type) {
  var length = parseFloat(document.getElementById('width').value) || 0; // Assuming 'width' is the length (L)

  if (type === 'meter') {
    // Only calculate based on length (L) for 'meter' type
    return length / 100; // Convert cm to meters
  } else if (type === 'meter-m3') {
    // Calculate based on length (L) x height (H) for 'meter-m3' type
    var height = parseFloat(document.getElementById('height').value) || 0;
    return length * height / 10000; // Convert cm² to m²
  }
  return 0;
}

// Helper function to calculate price based on product type
function calculatePrice(entry, meters) {
  var type = entry['theme-product-type'];
  var priceThreshold = parseFloat(entry['theme-product-price-threshold']) || 0; // Fetch the price threshold, defaulting to 0 if not present

  var finalPrice = 0;
  if (type === 'meter') {
    var pricePerMeter = parseFloat(entry['theme-product-price-meter']);
    finalPrice = meters * pricePerMeter; // Price calculation for 'meter' type
  } else if (type === 'meter-m3') {
    var pricePerMeterM3 = parseFloat(entry['theme-product-price-meter-m3']);
    finalPrice = meters * pricePerMeterM3; // Price calculation for 'meter-m3' type
  }
  return finalPrice + priceThreshold; // Add the price threshold to the final price
}