"use strict";

document.addEventListener('DOMContentLoaded', function () {
  var limit = 15;
  var currentIndex = 0;
  var category = '';
  var productImages = [];
  if (jQuery('div.product-image-gallery').length >= 1) {
    setCategory(null, '');
    selectTopButton('div.product-image-top-button-1');
  }
  jQuery('div.product-image-bottom-button').on('click', function (event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    limit += 15;
    fetchProductImages();
  });
  jQuery('div.product-image-top-button-1').on('click', function (event) {
    setCategory(event, '');
    selectTopButton('div.product-image-top-button-1');
  });
  jQuery('div.product-image-top-button-2').on('click', function (event) {
    setCategory(event, 'clickover_kozijnen');
    selectTopButton('div.product-image-top-button-2');
  });
  jQuery('div.product-image-top-button-3').on('click', function (event) {
    setCategory(event, 'clickover_deuren');
    selectTopButton('div.product-image-top-button-3');
  });
  jQuery('div.product-image-top-button-4').on('click', function (event) {
    setCategory(event, 'kozijnen');
    selectTopButton('div.product-image-top-button-4');
  });
  jQuery('div.product-image-top-button-5').on('click', function (event) {
    setCategory(event, 'deuren');
    selectTopButton('div.product-image-top-button-5');
  });
  jQuery('div.product-image-top-button-6').on('click', function (event) {
    setCategory(event, 'schuifpuien');
    selectTopButton('div.product-image-top-button-6');
  });
  jQuery('select.product-image-top-select').on('change', function (event) {
    switch (this.value) {
      case '1':
        setCategory(event, '');
        selectTopButton('div.product-image-top-button-1');
        break;
      case '2':
        setCategory(event, 'clickover_kozijnen');
        selectTopButton('div.product-image-top-button-2');
        break;
      case '3':
        setCategory(event, 'clickover_deuren');
        selectTopButton('div.product-image-top-button-3');
        break;
      case '4':
        setCategory(event, 'kozijnen');
        selectTopButton('div.product-image-top-button-4');
        break;
      case '5':
        setCategory(event, 'deuren');
        selectTopButton('div.product-image-top-button-5');
        break;
      case '6':
        setCategory(event, 'schuifpuien');
        selectTopButton('div.product-image-top-button-6');
        break;
    }
  });
  jQuery('div.product-image-overlay').on('click', function (event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    jQuery('div.product-image-overlay').css('display', 'none');
    jQuery('img.product-image-overlay-img').attr('src', '').attr('alt', '');
  });
  jQuery('div.product-image-overlay-img-left').on('click', function (event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    if (currentIndex !== 0) {
      currentIndex--;
    } else {
      currentIndex = productImages.length - 1;
    }
    loadPopupImage();
  });
  jQuery('div.product-image-overlay-img-right').on('click', function (event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    if (currentIndex < productImages.length - 1) {
      currentIndex++;
    } else {
      currentIndex = 0;
    }
    loadPopupImage();
  });
  jQuery('svg.product-image-overlay-navigation-left').on('click', function (event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    if (currentIndex !== 0) {
      currentIndex--;
    } else {
      currentIndex = productImages.length - 1;
    }
    loadPopupImage();
  });
  jQuery('svg.product-image-overlay-navigation-right').on('click', function (event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    if (currentIndex < productImages.length - 1) {
      currentIndex++;
    } else {
      currentIndex = 0;
    }
    loadPopupImage();
  });
  function setCategory(event, newCategory) {
    if (event !== null) {
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
    limit = 15;
    category = newCategory;
    fetchProductImages();
  }
  function fetchProductImages() {
    jQuery.ajax({
      url: 'https://kunststofkozijn.nl/wp-json/api/v1/wordpress/product-images',
      type: 'POST',
      data: {
        limit: limit,
        minimumPoints: 0,
        hardFilterCategory: category !== '' ? 1 : 0,
        hardFilterType: 0,
        filterValues: {
          productImageCategory: [category],
          productImageType: [],
          productImageStructureAndColor1: [],
          productImageStructureAndColor2: [],
          productImageProfile: [],
          productImageCornerJoint: [],
          productImagePanel: [],
          productImageSill: [],
          productImageWindowVent: [],
          productImageWindowMesh: [],
          productImageMailbox: []
        }
      },
      enctype: 'multipart/form-data',
      dataType: 'json',
      success: function success(response) {
        productImages = response.productImages;
        jQuery('div.product-image-gallery').empty();
        productImages.forEach(function (_productImage, index) {
          jQuery('div.product-image-gallery').append('<div class="product-image-gallery-item"><img data-index="' + index + '" class="product-image-gallery-item-img" src="' + productImages[index].url + '" alt="' + productImages[index].alt + '"/><div class="product-image-gallery-item-background" style="display: none;"></div><p class="product-image-gallery-item-title" style="display: none;">' + productImages[index].alt + '</p></div>');
        });
        if (response.isLast) {
          jQuery('.product-image-bottom-button').css('display', 'none');
        } else {
          jQuery('.product-image-bottom-button').css('display', 'block');
        }
        jQuery('div.product-image-gallery-item').on('mouseenter', function (event) {
          jQuery(event.currentTarget).find('.product-image-gallery-item-background').css('display', 'block');
          jQuery(event.currentTarget).find('.product-image-gallery-item-title').css('display', 'block');
        }).on('mouseleave', function (event) {
          jQuery(event.currentTarget).find('.product-image-gallery-item-background').css('display', 'none');
          jQuery(event.currentTarget).find('.product-image-gallery-item-title').css('display', 'none');
        }).on('click', function (event) {
          event.stopPropagation();
          event.stopImmediatePropagation();
          currentIndex = jQuery(event.currentTarget).find('.product-image-gallery-item-img').data('index');
          loadPopupImage();
        });
      }
    });
  }
  function loadPopupImage() {
    jQuery('div.product-image-overlay').css('display', 'block');
    jQuery('img.product-image-overlay-img').attr('src', productImages[currentIndex].url).attr('alt', productImages[currentIndex].alt);
    jQuery('p.product-image-overlay-description-entry-text-1').text(productImages[currentIndex].type);
    jQuery('p.product-image-overlay-description-entry-text-2').text(productImages[currentIndex].profile);
    jQuery('p.product-image-overlay-description-entry-text-3').text(productImages[currentIndex].corner);
    jQuery('p.product-image-overlay-description-entry-text-4').text(productImages[currentIndex].color);
    jQuery('span.product-image-overlay-navigation-current').text(currentIndex + 1);
    jQuery('span.product-image-overlay-navigation-total').text(productImages.length);
  }
  function selectTopButton(selectedClass) {
    jQuery('div.product-image-top-button').each(function (index, object) {
      jQuery(object).css('color', '#8E8E8E');
      jQuery(object).css('text-decoration', 'none');
      jQuery(object).css('text-decoration-color', '#8E8E8E');
      jQuery(object).css('font-weight', '400');
    });
    jQuery(selectedClass).css('color', '#EF8634');
    jQuery(selectedClass).css('text-decoration', 'underline');
    jQuery(selectedClass).css('text-decoration-color', '#EF8634');
    jQuery(selectedClass).css('font-weight', '600');
  }
});