"use strict";

document.addEventListener('DOMContentLoaded', function () {
  var imageContainer = document.querySelector('.video-container');
  if (imageContainer) {
    imageContainer.addEventListener('click', function () {
      var videoID = this.getAttribute('data-video-id');
      var videoURL = "https://www.youtube.com/embed/".concat(videoID, "?autoplay=1");

      // Show the iframe container
      document.querySelector('.iframe-container').style.display = 'block';

      // Hide the img-box by adding the 'hide' class
      var imgBox = document.querySelector('.img-container');
      if (imgBox) {
        imgBox.classList.add('hide');
      }

      // Set the YouTube video URL to play the video
      document.getElementById('youtubeVideo').src = videoURL;
    });
  }
});