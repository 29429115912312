"use strict";

document.addEventListener('DOMContentLoaded', function () {
  // Check if both .text-swiper and .images-swiper exist on the page
  var textSwiperElement = document.querySelector('.text-swiper');
  var imagesSwiperElement = document.querySelector('.images-swiper');
  if (textSwiperElement && imagesSwiperElement) {
    // Initialize Swiper for Text

    // Initialize Swiper for Images
    var imagesSwiper = new Swiper('.images-swiper', {
      loop: true,
      watchSlidesProgress: true
    });
    var textSwiper = new Swiper('.text-swiper', {
      effect: 'fade',
      loop: true,
      crossFade: true,
      autoplay: {
        delay: 5000
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      thumbs: {
        swiper: imagesSwiper
      }
    });
  }
});