"use strict";

document.addEventListener('DOMContentLoaded', function () {
  $(document).foundation();
  var nav = document.getElementById('site-navigation');
  var menuBtn = document.querySelector('.menu-btn');
  var lines = document.querySelectorAll('.menu-btn .line');
  var ul = document.querySelectorAll('nav#site-navigation ul li ul');
  var li = document.querySelectorAll('nav#site-navigation ul li ul li');
  var deleteSearchBtn = document.querySelector('#deleteSearch');
  var fadeElements = document.querySelectorAll('.fade-in');
  var header = document.querySelector('header');
  var navbarHeight = header.offsetHeight;
  var didScroll = false;
  var lastScrollTop = 0;
  var delta = 5;
  menuBtn.addEventListener('click', function () {
    nav.classList.toggle('open-nav');
    lines.forEach(function (line) {
      return line.classList.toggle('line-cross');
    });
    lines[1].classList.toggle('line-fade-out');
  });
  function isScrolledIntoView(elem) {
    var docViewTop = window.scrollY;
    var docViewBottom = docViewTop + window.innerHeight + 200;
    var elemTop = elem.offsetTop;
    var elemBottom = elemTop + elem.offsetHeight;
    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }
  ul.forEach(function (ul) {
    if (ul.classList.contains('children')) {
      ul.parentNode.classList.add('hasChildren');
    }
  });
  $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
    // disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });
  li.forEach(function (li) {
    if (li.classList.contains('hasChildren')) {
      li.querySelector('a').insertAdjacentHTML('beforeend', '<a href=\'javascript:void(0)\' class=\'expandChild\' title=\'Child item\'></a>');
    }
  });
  document.addEventListener('click', function (e) {
    if (!nav.contains(e.target)) {
      nav.classList.remove('nav-open');
    }
  });
  window.addEventListener('scroll', function () {
    fadeElements.forEach(function (element) {
      if (isScrolledIntoView(element)) {
        element.classList.add('animation-started');
      }
    });
    didScroll = true;
  });
  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);
  function hasScrolled() {
    var st = window.scrollY;
    if (Math.abs(lastScrollTop - st) <= delta) return;
    if (st > lastScrollTop && st > navbarHeight) {
      header.classList.remove('nav-down');
      header.classList.add('nav-up');
    } else {
      if (st + window.innerHeight < document.body.offsetHeight) {
        header.classList.remove('nav-up');
        header.classList.add('nav-down');
      }
    }
    lastScrollTop = st;
  }
  var swiper = new Swiper('.swiper-product-thumbs', {
    spaceBetween: 10,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesProgress: true
  });
  var swiper2 = new Swiper('.swiper-product-image', {
    spaceBetween: 10,
    autoHeight: true,
    autoplay: {
      delay: 5000
    },
    navigation: {
      nextEl: '.swiper-product-next',
      prevEl: '.swiper-product-prev'
    },
    a11y: {
      prevSlideMessage: 'Previous slide',
      nextSlideMessage: 'Next slide'
    },
    keyboard: {
      enabled: true
    },
    thumbs: {
      swiper: swiper
    }
  });
  var reviewSlider = new Swiper('.review-slider', {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    }
  });
  var swiperCardSlider = new Swiper('.swiper-card-slider', {
    spaceBetween: 10,
    slidesPerView: 1,
    autoHeight: true,
    pagination: {
      el: '.card-swiper-pagination',
      clickable: true
    },
    autoplay: {
      delay: 5000
    },
    breakpoints: {
      '1200': {
        slidesPerView: 4,
        spaceBetween: 30
      }
    }
  });
  jQuery('.footer-label').click(function ($) {
    jQuery(this).closest('.footer-menu').find('.footer-container ul').slideToggle();
    jQuery(this).closest('.footer-menu').find('i').toggleClass('open');
  });
  $('.read-more-btn').click(function () {
    var container = $(this).closest('.inner-container');
    var content = container.find('.text');
    content.removeClass('collapsed').css({
      'max-height': content.prop('scrollHeight') + 'px'
    });
    $(this).remove();
  });

  // Initialize Magnific Popup
  $('.magnific-popup').magnificPopup({
    type: 'image',
    mainClass: 'mfp-with-zoom',
    removalDelay: 300,
    gallery: {
      enabled: true
    }
  });
});